<template>
  <Loader :value="promise" theme="overlay" class="integration-webmegler-units">
    <table>
      <tr>
        <th>Oppdrag i Webmegler</th>
        <th>Enhet i Kvass</th>
      </tr>
      <tbody>
        <tr v-for="item in webmegler" :key="item.value">
          <td>{{ item.label }}</td>
          <td>
            <select :value="value.map[item.value]" @input="$ev => setMap(item, $ev)">
              <option :value="null"><label>Opprett ny enhet</label></option>
              <option :value="false"><label>Hopp over</label></option>
              <option v-for="kitem in kvass" :key="kitem.value" :value="kitem.value">{{ kitem.label }}</option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </Loader>
</template>

<script>
import { LoaderComponent as Loader } from 'vue-elder-loader'

function convertTarget(val) {
  switch (val) {
    case 'false':
      return false
    case '':
      return null
    default:
      return val
  }
}

export default {
  props: {
    id: String,
    value: Object,
  },
  inject: ['account', 'token', 'integration'],
  watch: {
    id: {
      handler: 'fetch',
      immediate: true,
    },
  },
  data() {
    return {
      promise: null,
      webmegler: [],
      kvass: [],
    }
  },
  methods: {
    fetch() {
      this.promise = fetch(`https://${this.account}/api/integration/${this.integration}/callbacks/map?id=${this.id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(res => {
          if (!res.ok) throw new Error()
          return res.json()
        })
        .then(({ map, kvass, webmegler }) => {
          this.$emit('input', { ...this.value, map })

          this.kvass = kvass
          this.webmegler = webmegler
        })
    },
    setMap(item, event) {
      let value = convertTarget(event.target.value)
      this.$emit('input', { ...this.value, map: { ...this.value.map, [item.value]: value } })
    },
  },
  components: {
    Loader,
  },
}
</script>

<style lang="scss">
.integration-webmegler-units {
  table {
    border-collapse: collapse;
    width: 100%;
    th,
    td {
      padding: 0.75rem;
      text-align: left;
    }

    tbody tr:nth-child(odd) {
      background-color: hsla(var(--border-color-h), var(--border-color-s), var(--border-color-l), 0.3);
    }
  }

  &__notset {
    font-style: italic;
    opacity: 0.7;
  }
}
</style>
