<template>
  <Card class="integration-webmegler-map">
    <template #default>
      <SelectComponent
        v-if="!fetchError"
        v-model="data.id"
        label="Velg hovedoppdrag:"
        sublabel="Oppdrag er hentet fra Webmegler (du må ha tilgang til oppdraget)"
        empty-label="Velg..."
        :items="getProjects"
      />
      <Alert v-if="fetchError" theme="error">{{ fetchError }} </Alert>
      <UnitMap v-if="data.id" v-model="data" :id="data.id" />
      <Alert>
        <a target="_blank" href="https://help.kvass.no/nb/articles/14530">Les mer om oppsett av integrasjon her</a>
      </Alert>
    </template>
    <template #footer>
      <Alert v-if="hasDuplicateSelection" theme="error"> Du har brukt en enhet i Kvass flere ganger. </Alert>
      <ButtonComponent
        label="Fullfør"
        :icon="['fal', 'arrow-to-right']"
        theme="primary"
        :disabled="isDisabled"
        @click="submit"
        :promise="promise"
      />
    </template>
  </Card>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SelectComponent from '@kvass/custom-fields/src/Fields/SelectModal'
import Card from '@kvass/card'
import { ButtonComponent } from 'vue-elder-button'
import UnitMap from '@/components/Webmegler/Map'
import { AlertComponent as Alert } from 'vue-elder-alert'

const noDupes = arr => {
  return arr.every(x => arr.filter(u => u === x).length <= 1)
}

export default {
  inject: ['account', 'token', 'integration', 'webmegler'],

  data() {
    return {
      promise: null,
      fetchError: '',
      data: {
        id: this.webmegler || null,
        map: {},
      },
    }
  },
  computed: {
    hasDuplicateSelection() {
      return !noDupes(Object.values(this.data.map || {}).filter(Boolean))
    },

    isDisabled() {
      return !this.data.id || this.hasDuplicateSelection
    },
  },
  methods: {
    getProjects() {
      return fetch(`https://${this.account}/api/integration/${this.integration}/callbacks/projects`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then(res => {
        if (!res.ok) return (this.fetchError = 'Fant ingen oppdrag')
        return res.json()
      })
    },
    submit() {
      if (this.isDisabled) return

      this.promise = fetch(`https://${this.account}/api/integration/${this.integration}/callbacks/init`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.data),
      }).then(res => {
        if (!res.ok) throw new Error()
        return this.$emit('navigate', 'completed')
      })
    },
  },
  components: {
    FontAwesomeIcon,
    SelectComponent,
    Card,
    UnitMap,
    ButtonComponent,
    Alert,
  },
}
</script>

<style lang="scss">
.integration-webmegler-map {
  .kvass-card__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
