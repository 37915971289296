<template>
  <div class="integration-webmegler view">
    <div class="integration-webmegler__logo">
      <img src="../assets/webmegler.png" alt="webmegler-logo" />
      <FontAwesomeIcon :icon="['fal', 'long-arrow-right']" size="2x" />
      <img :src="`https://${account}/api/client/logo`" />
    </div>
    <component :is="component" @navigate="$ev => (step = $ev)" />
  </div>
</template>

<script>
import Steps from '@/components/Webmegler/Steps'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  props: {
    integration: String,
    account: String,
    token: String,
    webmegler: String,
  },
  provide() {
    return this.$props
  },
  computed: {
    component() {
      return Steps[this.step]
    },
  },
  data() {
    return {
      step: 'map',
    }
  },
  components: {
    FontAwesomeIcon,
  },
}
</script>

<style lang="scss">
.integration-webmegler {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $background-grey;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 3rem;

    svg {
      opacity: 0.3;
    }

    img {
      max-height: 35px;
    }
  }

  .kvass-card {
    max-width: 600px;
    width: 100%;

    &__footer-content {
      justify-content: center;
      align-items: start;
    }
  }
}
</style>
